import { ChevronDownIcon } from '@heroicons/react/solid';
import { Menu, MenuButton, MenuList } from '@reach/menu-button';
import React from 'react';
import 'twin.macro';
import DropdownLink from './DropdownLink';

const Dropdown: React.FC = () => (
  <Menu>
    <MenuButton tw="flex items-center space-x-2 rounded py-3 px-6 bg-gray-50 text-primary font-bold text-xl uppercase z-50 hover:(bg-dark text-gray-50) focus:(outline-none ring-4 ring-primary ring-opacity-60)">
      <span>Devenir Courtier D’assurances</span>
      <ChevronDownIcon tw="h-6 w-6" />
    </MenuButton>
    <MenuList tw="flex flex-col rounded bg-white shadow-xl mt-1 py-2 border-secondary z-50">
      <DropdownLink href="https://www.rccaq.com/AccesProfession_fr.html">Québec</DropdownLink>
      <DropdownLink href="https://www.nbinsurancebrokers.ca/become_broker_fr.html">
        Nouveau-Brunswick
      </DropdownLink>
      <DropdownLink href="https://ibaa.ca/education/become-broker.html">Alberta</DropdownLink>
      <DropdownLink href="https://bcbroker.ca/">Columbie-Britannique</DropdownLink>
      <DropdownLink href="http://ibam.mb.ca/education/">Manitoba</DropdownLink>
      <DropdownLink href="https://www.iban.ca/for_students.html">
        Terre-Neuve-et-Labrador
      </DropdownLink>
      <DropdownLink href="https://ibaa.ca/education/become-broker.html">
        Territoires du Nord-Ouest
      </DropdownLink>
      <DropdownLink href="https://www.ibans.com/courses.html">Nouvelle-Écosse</DropdownLink>
      <DropdownLink href="https://ibaa.ca/education/become-broker.html">Nunavut</DropdownLink>
      <DropdownLink href="https://www.ibao.org/education/become-a-broker/">Ontario</DropdownLink>
      <DropdownLink href="https://ibapei.wordpress.com/home/">Île-du-Prince-Édouard</DropdownLink>
      <DropdownLink href="https://www.ibas.ca/become-a-broker.html">Saskatchewan</DropdownLink>
      <DropdownLink href="https://ibaa.ca/education/become-broker.html">Yukon</DropdownLink>
    </MenuList>
  </Menu>
);

export default Dropdown;
