import React from 'react';
import tw from 'twin.macro';
import { Tab } from '@reach/tabs';

interface TabButtonProps {
  isSelected?: boolean;
  title: string;
}

const TabButton: React.FC<TabButtonProps> = ({ isSelected, title, children }) => (
  <Tab tw="flex space-x-3 font-bold border-0! bg-transparent rounded p-1 hover:(bg-gray-100) focus:(outline-none ring-4 ring-primary ring-opacity-60)">
    <span
      css={[tw`w-4 rounded block self-stretch`, isSelected ? tw`bg-primary` : tw`bg-transparent`]}
    />
    <span tw="flex flex-col items-start text-left">
      <span tw="text-primary text-2xl uppercase">{title}</span>
      <span tw="text-dark leading-3 text-base lg:(text-lg)">{children}</span>
    </span>
  </Tab>
);

export default TabButton;
