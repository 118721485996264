import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Tabs as ReachTabs, TabList, TabPanels, TabPanel } from '@reach/tabs';
import tw from 'twin.macro';

import TabButton from './TabButton';

const Tabs = () => {
  const tabImageStyle = tw`h-auto w-full object-cover`;

  return (
    <ReachTabs tw="my-12 grid! gap-12 md:(grid-cols-2) 2xl:(grid-cols-3)">
      <div tw="2xl:(col-span-2)">
        <p tw="text-primary mb-4 uppercase font-bold text-2xl lg:(text-4xl)">
          le courtier travaille pour toi, pas pour la compagnie d’assurance.
        </p>
        <TabList tw="flex flex-col space-y-4 bg-transparent">
          <TabButton title="Choix">
            Le courtier compare plusieurs assureurs parmi les meilleurs au Canada afin de trouver
            pour toi la bonne protection à un prix concurrentiel.
          </TabButton>
          <TabButton title="Confiance">
            La recommandation du courtier est impartiale, donc toujours dans ton intérêt.
          </TabButton>
          <TabButton title="Priorité">
            Tes intérêts. Une réclamation? Ton courtier s’assure qu’elle se règle de manière prompte
            et équitable.
          </TabButton>
          <TabButton title="Connaissances">
            Expert en évaluation des risques, ton courtier se perfectionne continuellement.
          </TabButton>
          <TabButton title="Innovation">
            Bien au fait de l’actualité, le courtier d’assurances utilise un éventail de
            technologies pour communiquer avec toi et répondre à tes besoins.
          </TabButton>
        </TabList>
      </div>
      <TabPanels tw="max-h-full 2xl:(col-span-1)">
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/couple-on-laptop.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/man-on-phone.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/woman-on-phone.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/man-taking-notes.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/woman-wearing-headset.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
      </TabPanels>
    </ReachTabs>
  );
};

export default Tabs;
