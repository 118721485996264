import { ChevronDownIcon } from '@heroicons/react/solid';
import { Menu, MenuButton, MenuList } from '@reach/menu-button';
import React from 'react';
import 'twin.macro';
import DropdownLink from './DropdownLink';

const Dropdown: React.FC = () => (
  <Menu>
    <MenuButton tw="flex items-center space-x-2 rounded py-3 px-6 bg-gray-50 text-primary font-bold text-xl uppercase z-50 hover:(bg-dark text-gray-50) focus:(outline-none ring-4 ring-primary ring-opacity-60)">
      <span>Trouver un courtier</span>
      <ChevronDownIcon tw="h-6 w-6" />
    </MenuButton>
    <MenuList tw="flex flex-col rounded bg-white shadow-xl mt-1 py-2 border-secondary z-50">
      <DropdownLink href="https://www.rccaq.com/trouver_courtier_fr.html">Québec</DropdownLink>
      <DropdownLink href="https://www.nbinsurancebrokers.ca/find-a-broker_fr.html">
        Nouveau - Brunswick
      </DropdownLink>
      <DropdownLink href="https://ibaa.ca/consumers/find-broker.html">Alberta</DropdownLink>
      <DropdownLink href="https://ibabc.org/FindBroker">Colombie-Britannique</DropdownLink>
      <DropdownLink href="http://ibam.mb.ca/find-a-broker/">Manitoba</DropdownLink>
      <DropdownLink href="https://www.iban.ca/brokersmap.html">
        Terre-Neuve-et-Labrador
      </DropdownLink>
      <DropdownLink href="https://ibaa.ca/consumers/find-broker.html">
        Territoires du Nord-Ouest
      </DropdownLink>
      <DropdownLink href="https://www.ibans.com/brokersmap.html">Nouvelle-Écosse</DropdownLink>
      <DropdownLink href="https://ibaa.ca/consumers/find-broker.html">Nunavut</DropdownLink>
      <DropdownLink href="https://ontariobrokers.ca/">Ontario</DropdownLink>
      <DropdownLink href="https://ibapei.wordpress.com/locations/">
        Île-du-Prince-Édouard
      </DropdownLink>
      <DropdownLink href="https://www.ibas.ca/cgi/page.cgi/find-a-broker.html">
        Saskatchewan
      </DropdownLink>
      <DropdownLink href="https://ibaa.ca/consumers/find-broker.html">Yukon</DropdownLink>
    </MenuList>
  </Menu>
);

export default Dropdown;
