/* eslint-disable import/no-duplicates */
import * as React from 'react';
import { GlobalStyles } from 'twin.macro';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import "@reach/menu-button/styles.css";
import '@reach/skip-nav/styles.css';
import '@reach/tabs/styles.css';

import "@fontsource/open-sans-condensed/300.css";
import "@fontsource/open-sans-condensed/700.css";

import { ThemeProvider } from '~theme';

import Footer from './Footer';

const Layout: React.FC = ({ children }) => (
  <ThemeProvider>
    <GlobalStyles />
    <SkipNavLink />
    <div tw="font-sans text-base bg-white text-gray-900 min-h-screen flex flex-col overflow-x-hidden">
      <SkipNavContent>
        <main id="main">{children}</main>
      </SkipNavContent>
      <Footer />
    </div>
  </ThemeProvider>
);

export default Layout;
