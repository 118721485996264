import React from 'react';

const Twitter: React.FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 23" {...props}>
    <path
      fill="#8F307A"
      d="M24.572 5.544c.018.243.018.486.018.73 0 7.42-5.648 15.97-15.97 15.97-3.18 0-6.135-.921-8.62-2.52.452.052.886.07 1.355.07 2.624 0 5.04-.887 6.969-2.399a5.623 5.623 0 01-5.248-3.892c.347.052.695.086 1.06.086.504 0 1.008-.07 1.477-.19a5.614 5.614 0 01-4.5-5.51V7.82a5.653 5.653 0 002.536.712 5.61 5.61 0 01-2.502-4.674 5.57 5.57 0 01.765-2.833A15.955 15.955 0 0013.485 6.9a6.341 6.341 0 01-.139-1.286A5.61 5.61 0 0118.96 0a5.6 5.6 0 014.101 1.773A11.05 11.05 0 0026.623.417a5.598 5.598 0 01-2.468 3.093 11.253 11.253 0 003.232-.869 12.066 12.066 0 01-2.815 2.903z"
    ></path>
  </svg>
);

export default Twitter;
